// Import the functions you need from the SDKs you need
import {type FirebaseOptions, initializeApp} from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "@firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export default defineNuxtPlugin(nuxtApp => {
    const config = useRuntimeConfig()

    console.log('firebase.global plugin run')

    const firebaseConfig: FirebaseOptions = {
        apiKey: config.public.fbApiKey as string,
        authDomain: config.public.fbAuthDomain as string,
        projectId: config.public.fbProjectId as string,
        storageBucket: config.public.fbStorageBucket as string,
        messagingSenderId: config.public.fbMessagingSenderId as string,
        appId: config.public.fbAppId as string,
        measurementId: config.public.fbMeasurementId as string
    };

// Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const auth = getAuth(app)
    nuxtApp.vueApp.provide('auth', auth)
    nuxtApp.provide('auth', auth)
})
