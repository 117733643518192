import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as map8NxtI69oDxMeta } from "/app/pages/map.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as tidepagevtiHF3VLdmMeta } from "/app/pages/tidepage.vue?macro=true";
export default [
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about",
    path: aboutu8Sb32ihwPMeta?.path ?? "/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: map8NxtI69oDxMeta?.name ?? "map",
    path: map8NxtI69oDxMeta?.path ?? "/map",
    meta: map8NxtI69oDxMeta || {},
    alias: map8NxtI69oDxMeta?.alias || [],
    redirect: map8NxtI69oDxMeta?.redirect,
    component: () => import("/app/pages/map.vue").then(m => m.default || m)
  },
  {
    name: registermu8R7rSP5uMeta?.name ?? "register",
    path: registermu8R7rSP5uMeta?.path ?? "/register",
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: tidepagevtiHF3VLdmMeta?.name ?? "tidepage",
    path: tidepagevtiHF3VLdmMeta?.path ?? "/tidepage",
    meta: tidepagevtiHF3VLdmMeta || {},
    alias: tidepagevtiHF3VLdmMeta?.alias || [],
    redirect: tidepagevtiHF3VLdmMeta?.redirect,
    component: () => import("/app/pages/tidepage.vue").then(m => m.default || m)
  }
]