<template>
  <div class="top-container">
  <NuxtLayout>
    <NuxtPage/>
  </NuxtLayout>
  </div>
</template>
<style scoped>
.top-container {
  height: 92vh;
  flex: 1;
}

</style>